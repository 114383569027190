var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"formValidation"},[_c('b-form',[_c('b-row',{staticClass:"align-content-center mx-auto d-flex flex-column"},[_c('b-col',{attrs:{"lg":"8","md":"10","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Nombre","label-for":"first_name"}},[_c('validation-provider',{attrs:{"name":"nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"first_name","state":errors.length > 0 ? false:null,"placeholder":"Nombre"},model:{value:(_vm.first_name),callback:function ($$v) {_vm.first_name=$$v},expression:"first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"8","md":"10","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Apellido","label-for":"last_name"}},[_c('validation-provider',{attrs:{"name":"apellido","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"last_name","state":errors.length > 0 ? false:null,"placeholder":"Apellido"},model:{value:(_vm.last_name),callback:function ($$v) {_vm.last_name=$$v},expression:"last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"8","md":"10","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"emailValue"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"email","placeholder":"Email"},model:{value:(_vm.emailValue),callback:function ($$v) {_vm.emailValue=$$v},expression:"emailValue"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"8","md":"10","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Categoría","label-for":"categories"}},[_c('validation-provider',{attrs:{"name":"categoría","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":'ltr',"label":"name","state":errors.length > 0 ? false : null,"options":_vm.rowsCategories,"multiple":"","select-size":6},model:{value:(_vm.categories),callback:function ($$v) {_vm.categories=$$v},expression:"categories"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("No hay data para cargar")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.id === undefined)?_c('b-col',{attrs:{"lg":"8","md":"10","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Contraseña","label-for":"password"}},[_c('validation-provider',{attrs:{"name":"contraseña","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"text","placeholder":"Contraseña"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2028925352)})],1)],1):_vm._e(),(_vm.id === undefined)?_c('b-col',{attrs:{"lg":"8","md":"10","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Confirmación de contraseña","label-for":"password_confirmation"}},[_c('validation-provider',{attrs:{"name":"confirmación de contraseña","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"text","placeholder":"Confirmación de contraseña"},model:{value:(_vm.password_confirmation),callback:function ($$v) {_vm.password_confirmation=$$v},expression:"password_confirmation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,771348509)})],1)],1):_vm._e(),_c('b-col',{attrs:{"lg":"8","md":"10","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Role","label-for":"role"}},[_c('validation-provider',{attrs:{"name":"role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":'ltr',"label":"name","state":errors.length > 0 ? false : null,"options":_vm.rowsRoles,"select-size":6},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("No hay data para cargar")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"8","md":"10","sm":"12"}},[(_vm.id === undefined ? _vm.accessPermissions('users.store') : _vm.accessPermissions('users.update'))?_c('b-button',{staticClass:"float-right text-right",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.onHandleValidationForm.apply(null, arguments)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SaveIcon"}}),_vm._v(" Guardar ")],1):_vm._e(),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"float-right mr-1",attrs:{"variant":"secondary","to":{ name: 'settings-agents' }}},[_vm._v(" Cancelar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }